<template>
  <section id="homeSearchBoxContainer">
    <div id="title">
      <h2>جستجو در فروشگاه آنلاین آریا</h2>
    </div>
  <section id="homeSearchBox" class="bg-white box-shaddow20 text-right">
    
    <div class="container">
      <div class="multiselect-form w-100">
        <Multiselect
          v-model="selected_brand"
          open-direction="bottom"
          :options="brand"
          track-by="name"
          placeholder="برند"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
          label="name"
        >
        </Multiselect>
        <Multiselect
          v-model="selected_model"
          open-direction="bottom"
          :options="model"
          track-by="name"
          placeholder="مدل"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
          label="name"
        >
        </Multiselect>
        <Multiselect
          v-model="selected_tips"
          open-direction="bottom"
          :options="tip"
          track-by="name"
          placeholder="تیپ"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
          label="name"
        >
        </Multiselect>
        <button
          @click.prevent="gotoSearchPage"
          type="button"
          class="search-btn border-0 rounded w-100 py-2 px-3 text-color-444 fontsize-small box-shaddow20 text-nowrap border"
        >
          جستجو
        </button>
      </div>
    </div>
  </section>
</section>
</template>

<script>
import { openSearch } from "../../../libraries/functions";
import Multiselect from "vue-multiselect";

export default {
  name: "MainSearch",
  components: { Multiselect },
  data() {
    return {
      searchList: [],
      search: "",
      showResult: false,
      searching: false,
      selected_brand: null,
      selected_tips: null,
      selected_model: null,
      brand: [
        {
          id: 1,
          name: "ایران خودرو",
        },
        {
          id: 2,
          name: "سایپا",
        },
        {
          id: 3,
          name: "چری",
        },
        {
          id: 4,
          name: "کرمان موتور",
        },
      ],
      model: [
        {
          id: 1,
          name: "پراید",
        },
        {
          id: 2,
          name: "ساینا",
        },
        {
          id: 3,
          name: "تیگو",
        },
        {
          id: 4,
          name: "پژو 206",
        },
      ],
      tip: [
        {
          id: 1,
          name: "T(2)",
        },
        {
          id: 2,
          name: "111",
        },
        {
          id: 3,
          name: "151",
        },
        {
          id: 4,
          name: "132",
        },
      ],
    };
  },
  methods: {
    showCloseSearchBox() {
      openSearch();
    },

    // نمایش محصول بعد از سرچ و کلیک بروی نمایش
    showProduct(product) {
      this.showResult = false;
      setTimeout(() => {
        this.$router.push({
          name: "product.detail",
          params: { id: product.id, slug: product.slug },
        });
      }, 1000);
    },
    // این متد برای زمانی که بروی اینپوت کلیک میشود فراخوانی میشود
    focusInput() {
      if (this.products != null) {
        this.showResult = true;
      }
    },
    // جستجو در میان لیست محصولات بعد از پایان نوشتن مشتری
    searchRequest() {
      if (this.search != "" && this.search.length > 1) {
        this.showResult = true;
        this.searching = true;
        this.$store.dispatch("front/searchInProducts", this.search);
      }
    },
    // تابع با فشردن اینتر به صفحه محصولات هدایت میکند
    gotoSearchPage() {
      let search = this.search;
      if (this.$route.name == "products") {
        this.search = "";
        this.$router.push({ name: "products", query: { title: search } });
      } else {
        return this.$router.push({
          name: "products",
          query: { title: search },
        });
      }
    },
  },
  watch: {
    // status(val) {
    //   if (val == false) {
    //     this.searching = false;
    //   }
    // },
    // search(newVal) {
    //   // تماشا کردن تغییرات اینپوت سرچ و اگر بعد از اخرین حرف وارده حرفی وارد نکند سرچ انجام میشود
    //   this.searchList.push(newVal);
    //   let last = this.searchList.length;
    //   setTimeout(() => {
    //     if (this.searchList[last] == undefined) {
    //       this.searchList = [];
    //       this.searchRequest();
    //     }
    //   }, 1500);
    // },
  },
};
</script>

<style scoped>
section#homeSearchBoxContainer #title h2 {
  position: absolute;
  font-size: 36px;
  color: #0b0b61;
  top: 100%;
  font-family: "yekan-bakh";
  transform: translateY(-100%);
}
section#homeSearchBoxContainer #title img {
  width: 25%;
}
section#homeSearchBoxContainer #title {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
.multiselect-form {
  display: grid;
  gap: 5px;
  margin: 10px 0;
  grid-template-columns: repeat(4, 1fr);
}
section#homeSearchBoxContainer {
  padding: 20px 0;
  margin-top: 50px;
}
section#homeSearchBox {
  background-image: url(../../../assets/images/searchBg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 45px 0;
  margin-top: 20px;
}
section#homeSearchBox h3 {
  text-align: center;
  font-family: "yekan-bakh-bold";
}

.search-btn {
  transition: 0.3s;
  background-color: var(--color-theme) !important;
  color: white;
}
.search-btn:hover {
  color: white;
}
@media (max-width: 768px) {
  section#homeSearchBoxContainer #title h2 {
    display: none;
  }
}
@media (max-width: 450px) {
  .multiselect-form {
  grid-template-columns: repeat(2, 1fr);
}
}

</style>
