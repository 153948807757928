<template>
  <span
    v-if="product && product.chargeable && product.status == 'out_of_stock'"
  >
    <a
      v-if="isListen"
      @click="unlistenRequet()"
      :class="{ 'disable-bell': listenDisabled }"
    >
      <BellOff />
    </a>
    <a
      v-else
      @click="listenRequest()"
      :class="{ 'disable-bell': listenDisabled }"
      title="در صورت موجود شدن به من اطلاع بده"
    >
      <BellOn />
    </a>
  </span>
</template>

<script>
import BellOn from "@/parts/Front/components/svg/BellOn.vue";
import BellOff from "@/parts/Front/components/svg/BellOff.vue";
export default {
  inject: ["mediaQueries"],
  components: { BellOn, BellOff },
  props: {
    product: {
      type: Object,
      default: null,
    },
    selectedVariety: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      listenDisabled: false,
      disabled: false,
      isListen: false,
    };
  },
  watch: {
    value(newVal) {
      this.product = newVal;
      this.selectFirstIfNotSelected();
    },
    product(newVal) {
      this.$emit("input", newVal);
    },
  },
  mounted() {
    this.$store.commit("front/addHomeRetrievedEvent", () => {
      if (this.listenChargeList && this.listenChargeList != "") {
        this.isListen = !!this.listenChargeList.filter(
          (item) => item.product_id == this.product.id
        ).length;
      }
    });
  },
  computed: {
    listenChargeList() {
      if (
        this.$store.getters["front/getHomeData"] != null &&
        this.$store.getters["front/getHomeData"].user.user != null
      ) {
        return this.$store.getters["front/getHomeData"].user.user
          .listen_charges;
      } else {
        return null;
      }
    },
  },
  methods: {
    selectFirstIfNotSelected() {
      this.product.selectFirstIfNotSelected();
    },
    // آیا این کاربر قبلا برای مطلع شدن از موجود شدن این محصول اقدام کرده
    async listenRequest() {
      let homeData = this.$store.getters["front/getHomeData"];
      //   if (!homeData.user.user) {
      //     window.$("#loginModal").modal("show");
      //     return;
      //   }
      try {
        this.listenDisabled = true;
        const response = await this.$axios.post(
          `customer/products/${this.product.id}/listen`
        );
        homeData.user.user.listen_charges.push(response.data.data.listenCharge);
        this.$root.notify(response, "success");
        this.isListen = true;
      } catch (error) {
        this.$root.notify(error);
      }
      this.listenDisabled = false;
    },
    async unlistenRequet() {
      let homeData = this.$store.getters["front/getHomeData"];
      try {
        this.listenDisabled = true;
        const response = await this.$axios.delete(
          `customer/products/${this.product.id}/unlisten`
        );
        homeData.user.user.listen_charges =
          homeData.user.user.listen_charges.filter((listenCharge) => {
            return listenCharge.product_id != this.product.id;
          });
        this.$root.notify(response, "success");
        this.isListen = false;
      } catch (error) {
        this.$root.notify(error);
      }
      this.listenDisabled = false;
    },
  },
};
</script>

<style scoped>
.disable-bell {
  pointer-events: none;
  opacity: 0.6;
  user-select: none;
}
</style>
