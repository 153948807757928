<template>
  <section class="memberdhip-form">
    <div class="booking">
      <div class="container" id="memeberShipContainer">
        <div
          class="row align-items-stretch"
          :class="{ restyleRegisterModeRow: hashMode == true }"
        >
          <div id="logoRegisterMode" class="col-lg-6" v-if="hashMode">
            <img src="@/assets/images/logo.png" alt="لوگو آریا جانبی" />
          </div>
          <div class="col-lg-6" :class="{ JustformView: hashMode == true }">
            <div class="booking-form-area booking-form-area-3 h-100">
              <div class="booking-form-content-3">
                <div class="default-section-title default-section-title-middle">
                  <h3>درخواست عضویت</h3>
                  <p class="colorWhite">
                    در تمامی این سالها با هدف ایجاد خدمت رسانی در خریدی آسان و
                    مطمئن به همکاران محترم در کنار شما بوده ایم و همه تلاشمان
                    عرضه کالایی با کیفیت،و قیمت مناسب که شایسته ایران و ایرانی
                    باشد را داشته ایم و خواهیم داشت
                  </p>
                </div>
                <form @submit.prevent="submitMember">
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="booking-form-content">
                        <input
                          ref="first_name"
                          type="text"
                          class="form-control"
                          placeholder="نام *"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="booking-form-content">
                        <input
                          ref="last_name"
                          type="text"
                          class="form-control"
                          placeholder="نام خانوادگی *"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="booking-form-content">
                        <input
                          ref="mobile"
                          type="tel"
                          class="form-control"
                          placeholder="تلفن *"
                          @keypress="keypressHandler"
                        />
                      </div>
                    </div>
                    <div
                      v-if="provinces != null"
                      class="col-lg-6 col-md-6 col-sm-6 col-12 mt-3"
                    >
                      <multiselect
                        ref="provinceMultiselect"
                        v-model="province_id"
                        :allow-empty="false"
                        placeholder="استان *"
                        open-direction="bottom"
                        :options="provinces"
                        label="name"
                        track-by="id"
                        :searchable="true"
                        :multiple="false"
                        :close-on-select="true"
                        :show-labels="false"
                      >
                        <template slot="noOptions"> موردی یافت نشد </template>
                        <template slot="noResult"> موردی یافت نشد </template>
                      </multiselect>
                    </div>
                    <div
                      v-if="cities != null"
                      class="col-lg-6 col-md-6 col-sm-6 col-12 mt-3"
                    >
                      <multiselect
                        ref="cityMultiselect"
                        v-model="city_id"
                        :allow-empty="false"
                        placeholder="شهر *"
                        open-direction="bottom"
                        :options="cities"
                        label="name"
                        track-by="id"
                        :searchable="true"
                        :multiple="false"
                        :close-on-select="true"
                        :show-labels="false"
                      >
                        <template slot="noOptions"> موردی یافت نشد </template>
                        <template slot="noResult"> موردی یافت نشد </template>
                      </multiselect>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="booking-form-content">
                        <input
                          ref="marketerCode"
                          type="text"
                          class="form-control"
                          placeholder="کد معرف "
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="booking-form-content">
                        <button
                          :class="{ disabled: disabled }"
                          class="default-button"
                          type="submit"
                        >
                          <span> عضویت </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div v-if="!hashMode" class="col-lg-6 d-none d-lg-block">
            <div class="w-100 h-100">
              <img
                class="w-100 h-100"
                :src="`https://api.ariyajanebi.ir/storage/${settings?.settings?.landing?.landing_membership}`"
                alt="تصویر عضویت لندینگ"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: { Multiselect },
  props: {
    hashMode: Boolean,
  },
  data() {
    return {
      cities: null,
      disabled: false,
      province_id: null,
      city_id: null,
    };
  },
  methods: {
    async submitMember() {
      if (this.$refs.first_name.value.length < 2) {
        this.$refs.first_name.classList.add("inputError");
        return window
          .swal({
            title: "وارد کردن  نام الزامی است",
            text: "نام باید شامل دو کارکتر یا بیشتر باشد",
            icon: "warning",

            button: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.first_name.focus();
          });
      } else {
        this.$refs.first_name.classList.remove("inputError");
      }
      if (this.$refs.last_name.value.length < 2) {
        this.$refs.last_name.classList.add("inputError");
        return window
          .swal({
            title: "وارد کردن  نام خانوادگی الزامی است",
            text: "نام خانوادگی باید شامل دو کارکتر یا بیشتر باشد",
            icon: "warning",

            button: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.last_name.focus();
          });
      } else {
        this.$refs.last_name.classList.remove("inputError");
      }
      if (this.$refs.mobile.value.length != 11) {
        this.$refs.mobile.classList.add("inputError");
        return window
          .swal({
            title: "وارد کردن شماره تماس الزامی است",
            text: "شماره تماس شامل 11 رقم است",
            icon: "warning",

            button: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.mobile.focus();
          });
      } else {
        this.$refs.mobile.classList.remove("inputError");
      }
      if (!this.province_id) {
        this.$refs.provinceMultiselect.$el.classList.add("inputError");
        return window
          .swal({
            title: "انتخاب استان الزامی است",
            icon: "warning",
            button: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.provinceMultiselect.$el.focus();
          });
      } else {
        this.$refs.provinceMultiselect.$el.classList.remove("inputError");
      }
      if (this.province_id && this.province_id.id && !this.city_id) {
        this.$refs.cityMultiselect.$el.classList.add("inputError");
        return window
          .swal({
            title: "انتخاب شهر الزامی است",
            icon: "warning",
            button: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.cityMultiselect.$el.focus();
          });
      } else {
        this.$refs.cityMultiselect.$el.classList.remove("inputError");
      }
      
      try {
        this.disabled = true;

        let formData = new FormData();
        formData.append("first_name", this.$refs.first_name.value);
        formData.append("last_name", this.$refs.last_name.value);
        formData.append("mobile", this.$refs.mobile.value);
        formData.append("province_id", this.province_id.id);
        formData.append("city_id", this.city_id.id);
        formData.append("representation_code", this.$refs.marketerCode.value);

        const response = await this.$axios.post("customer/register", formData);
        this.$refs.first_name.value = null;
        this.$refs.last_name.value = null;
        this.$refs.mobile.value = null;
        this.province_id.id = null;
        this.city_id.id = null;
        this.$root.notify(response.data.message, "success");
      } catch (error) {
        this.disabled = false;
        this.$root.notify(error);
      } finally {
        this.disabled = false;
      }
    },
    keypressHandler(event) {
      if (event.charCode < 48 || event.charCode > 57) event.preventDefault();
    },
  },

  computed: {
    provinces() {
      return this.$store.getters["front/getProvincesData"];
    },
    settings() {
      return this.$store.getters["front/getLandingHome"];
    },
  },
  mounted() {
    if (this.provinces == null) {
      this.$store.dispatch("front/getProvincesDataFromServer");
    }
  },
  watch: {
    province_id(newVal) {
      if (newVal) {
        let cities = this.provinces.filter((item) => item.id == newVal.id)[0]
          .cities;
        this.cities = cities;
      }
    },
  },
};
</script>

<style scoped>
.memberdhip-form .multiselect__tags,
.memberdhip-form .form-control {
  border: 1px solid var(--color-ea);
  border-radius: 10px !important;
}
.multiselect .multiselect__tags {
  border-radius: 10px !important;
}
.multiselect .multiselect__placeholder {
  color: #777;
  margin-bottom: 4px !important;
}

.memberdhip-form .multiselect,
.memberdhip-form .multiselect__input,
.memberdhip-form .multiselect__single,
.memberdhip-form .form-control {
  font-size: 16px !important;
}

.memberdhip-form .multiselect__option--highlight::after,
.memberdhip-form .multiselect__option--highlight {
  background: var(--color-theme);
}

.memberdhip-form
  .multiselect__option--selected.multiselect__option--highlight::after,
.memberdhip-form .multiselect__option--selected.multiselect__option--highlight {
  background: var(--color-theme);
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.inputError {
  border: 1px solid red !important;
  background: #efe1e4;
}
#memeberShipContainer {
  padding: 0 !important;
  max-width: 100% !important;
  overflow: hidden;
}
.JustformView {
  max-width: calc(50% - 48px);
  margin: auto 24px;
}
.col-lg-6 {
  padding: 0 !important;
}
form .col-lg-6 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
div#logoRegisterMode img {
  width: 45%;
}
div#logoRegisterMode {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
.restyleRegisterModeRow {
  position: fixed;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-44%, -50%);
}
@media (max-width: 1000px) {
  .restyleRegisterModeRow {
    position: static;
    transform: unset;
    margin: 0;
  }
  .JustformView {
    max-width: 100%;
    margin: auto;
    position: static;
    transform: unset;
  }
  div#logoRegisterMode {
    height: auto;
  }
}
@media (max-width: 768px) {
  #memeberShipContainer {
    padding: 24px !important;
  }
}
</style>
