<template>
  <section>
    <div class="features features-bg pt-70 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-lg-12 col-md-12 col-12">
            <div class="default-section-title">
              <h6>ویژگی های آریا جانبی</h6>
              <h3>ویژگی هایی که ما را متمایز می کند</h3>
              <p>
                امیدواریم از محصولات ما به همان اندازه که ما از ارائه آن ها به
                شما لذت می بریم لذت ببرید.
              </p>
            </div>
          </div>
          <div class="col-xl-8 col-lg-12 col-md-12 col-12">
            <div class="features-card-area">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="features-card fmt-0">
                    <div class="features-icon-area">
                      <div class="features-card-icons">
                        <img
                          class="shape1"
                          src="@/assets/images/landing/shape/shape-1.png"
                          alt="image"
                        />
                        <img
                          class="shape2"
                          src="@/assets/images/landing/shape/shape-1-1.jpg"
                          alt="image"
                        />
                        <img width="40" height="40" class="features-card-icon-img" src="@/assets/images/w-invoice.png" alt="">
                      </div>
                    </div>
                    <div class="features-card-text">
                      <h4>صدور فاکتور رسمی</h4>
                      <p>
                        صدور فاکتور قانونی برای اداره مالیات که تمامی مالیات
                        کالای خریداری شده توسط آریا گستر پرداخت گردیده.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="features-card fmt-0">
                    <div class="features-icon-area">
                      <div class="features-card-icons">
                        <img
                          class="shape1"
                          src="@/assets/images/landing/shape/shape-1.png"
                          alt="image"
                        />
                        <img
                          class="shape2"
                          src="@/assets/images/landing/shape/shape-1-1.jpg"
                          alt="image"
                        />
                        <img width="40" height="40" class="features-card-icon-img" src="@/assets/images/w-legal.png" alt="">
                      </div>
                    </div>
                    <div class="features-card-text">
                      <h4>تمامی کالاها قانونی می باشد</h4>
                      <p>
                        کالاها کاملا قانونی و به هیچ عنوان قاچاق نمی باشد و
                        ضمانت و اصالت کالا تضمین شده می باشد.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="features-card">
                    <div class="features-icon-area">
                      <div class="features-card-icons">
                        <img
                          class="shape1"
                          src="@/assets/images/landing/shape/shape-1.png"
                          alt="image"
                        />
                        <img
                          class="shape2"
                          src="@/assets/images/landing/shape/shape-1-1.jpg"
                          alt="image"
                        />
                        <img width="40" height="40" class="features-card-icon-img" src="@/assets/images/w-support.png" alt="">
                      </div>
                    </div>
                    <div class="features-card-text">
                      <h4>پشتیبانی 24 ساعته با 10 اپراتور فعال</h4>
                      <p>
                        10 خط فعال با کارشناسان مجرب حوزه لوازم جانبی موبایل
                        آماده خدمت به همکاران گرامی را دارند.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="features-card">
                    <div class="features-icon-area">
                      <div class="features-card-icons">
                        <img
                          class="shape1"
                          src="@/assets/images/landing/shape/shape-1.png"
                          alt="image"
                        />
                        <img
                          class="shape2"
                          src="@/assets/images/landing/shape/shape-1-1.jpg"
                          alt="image"
                        />
                        <img width="40" height="40" class="features-card-icon-img" src="@/assets/images/w-warranty.png" alt="">
                      </div>
                    </div>
                    <div class="features-card-text">
                      <h4>امکان مرجوعی کالا</h4>
                      <p>
                        امکان مرجوعی کالا در صورتی که کالا مورد تایید نباشد با
                        تایید کارشناس طی مدت مشخص شده وجود دارد
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.features-card .features-card-icons .features-card-icon-img{
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
