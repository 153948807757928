<template>
  <section>
    <header class="border-bottom">
      <div class="header-area">
        <div class="navbar-area">
          <div class="main-responsive-nav">
            <div class="container">
              <div
                class="mobile-nav pt-2 align-items-center justify-content-between"
              >
                <router-link to="/landing" class="">
                  <img
                    class="black-logo"
                    width="130"
                    src="../../../../../assets/images/landing/logo.png"
                    alt="logo"
                  />
                </router-link>
                <router-link class="default-button" to="/login"
                  ><span>ورود به سایت</span></router-link
                >
              </div>
            </div>
          </div>
          <div class="main-nav">
            <div class="container">
              <nav
                class="navbar navbar-expand-md navbar-light justify-content-between py-0"
              >
                <router-link class="navbar-brand" to="/landing">
                  <img
                    class="black-logo"
                    src="../../../../../assets/images/landing/logo.png"
                    alt="logo"
                  />
               
                </router-link>
                <div
              class="collapse navbar-collapse mean-menu"
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav">
                <li class="nav-item">
                  <router-link to="/landing" class="nav-link">صفحه اصلی</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/landing-guideline" class="nav-link">آموزش خرید از سایت</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/landing-aboutus" class="nav-link">درباره ما</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/landing-contactus" class="nav-link">تماس با ما</router-link>
                </li>
              </ul>
            </div>
                <div class="menu-sidebar menu-sidebar-1">
                  <router-link class="default-button" to="/login"
                    ><span>ورود به سایت</span></router-link
                  >
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  </section>
</template>

<script>
export default {};
</script>

<style scoped></style>
