<template>
 <div v-if="!$root.homeData || $root.isLogin">
  <slider />


  <!-- <StoryCategories/> -->
    <main class="main container container-2 pt-1 p-sm-5">
      <!-- <SearchSection /> -->
      <specialCategoriesSlide/>
      <NewProduct/>
      <!-- <VipSection /> -->
      <!-- <FourBanners /> -->
      <!-- <MostDiscountProduct/> -->
      <Banners :banners="banners"></Banners>
      <AmazingProduct/>
      <!-- <RecommendedProduct></RecommendedProduct> -->
      <!-- <template v-if="$store.getters['front/getHomeData'] != null && $store.getters['front/getHomeData'].flashes != ''">
        <Flashes v-for="flash in $store.getters['front/getHomeData'].flashes" :key="flash.id" :flash="flash"></Flashes>
      </template> -->
      <Flashes v-if="$store.getters['front/getHomeData'] != null && $store.getters['front/getHomeData'].flashes != ''" :flashes="$store.getters['front/getHomeData'].flashes" />
      <MostSales/>
      <Weblogs></Weblogs>
      <!-- <LastInstaPosts class="mt-5" v-if="showInstagram"></LastInstaPosts> -->
<!--      <ScrollToTop></ScrollToTop>-->
      <section v-if="showFixedAds" class="col-6 col-lg-2 fixed-ads">
        <div @click="closeAdsModal" class="fontsize-large ads-close-btn">X</div>
        <video class="w-100" autoplay muted loop>
          <source
            src="../../../assets/images/advertise/index.mp4"
            type="video/mp4"
          />
        </video>
    </section>

    </main>

 </div>

</template>

<script>
import VipSection from "@/parts/Front/components/VipSection"
  import slider from '@/parts/Front/components/shared/Header'
  import RecommendedProduct from '@/parts/Front/components/RecommendedProduct'
  import Banners from '@/parts/Front/components/Banners'
  import Flashes from "@/parts/Front/components/FlashProducts";
  import Weblogs from "@/parts/Front/components/Weblogs";
  import SearchSection from "@/parts/Front/components/SearchSection"
  // import LastInstaPosts from "@/parts/Front/components/LastInstaPosts";
  import ScrollToTop from "@/parts/Front/components/ScrollToTop";
  import debounce from 'lodash/debounce'
  import FourBanners from "@/parts/Front/components/FourBanners";
import StoryCategories from "../components/StoryCategories.vue";
import MostDiscountProduct from "../components/MostDiscountProduct.vue";
import AmazingProduct from "../components/AmazingProduct.vue";
import MostSales from "../components/HomeProducts/MostSales.vue";
import NewProduct from "../components/HomeProducts/NewProduct.vue";
import specialCategoriesSlide from "../components/HomeProducts/specialCategoriesSlide.vue";
  export default {
    name: 'Home',
    components: {
      FourBanners,VipSection,
      slider,
      RecommendedProduct,
      Banners,
      Flashes,
      Weblogs,
      SearchSection,
      // LastInstaPosts,
      ScrollToTop,
      StoryCategories,
      MostDiscountProduct,
      AmazingProduct,
      MostSales,
      NewProduct,
      specialCategoriesSlide,
    },
    data() {
      return {
        showInstagram: true,
        reRenderInstagramFunction: debounce(this.reRenderInstagram, 250),
        showFixedAds:true
      }
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.reRenderInstagramFunction)
    },
    mounted() {
      window.removeEventListener('resize', this.reRenderInstagramFunction)
      window.addEventListener('resize', this.reRenderInstagramFunction)
    },
    methods: {
      reRenderInstagram() {
        this.showInstagram = false;
        this.$nextTick(() => {
          this.showInstagram = true
        })
      },
      closeAdsModal(){
        this.showFixedAds = false;
      }
    },
    computed:{
      banners() {
      if (this.$store.getters["front/getHomeData"] != null) {
        return this.$store.getters["front/getHomeData"].advertise;
        // let advertise = this.$store.getters['front/getHomeData'].advertise
        // return [
        //   [advertise.banner_1 , advertise.banner_2],
        //   [advertise.banner_3 , advertise.banner_4]
        // ]
      } else {
        return [];
      }
    },
    }
  }
</script>
<style scoped>
.fixed-ads {
  position: fixed;
  bottom: 1%;
  left: 1%;
  z-index: 100;
}
.ads-close-btn {
  font-weight: 900;
  color: #ffffff;
  position: absolute;
  top: 3%;
  right: 8%;
  z-index: 120;
  cursor: pointer;
}
@media screen and (max-width: 560px) {
  .fixed-ads {
    position: fixed;
    bottom: 10%;
    left: 1%;
    z-index: 100;
  }
  .ads-close-btn {
  font-weight: 900;
  color: #ffffff;
  position: absolute;
  top: 3%;
  right: 10%;
  z-index: 120;
  cursor: pointer;
}
}
</style>