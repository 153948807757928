<template>
  <section>
    <div class="contact-page ptb-70">
      <div class="container">
        <div class="default-section-title">
          <h6>ارسال پیام</h6>
          <h3>چگونه به شما کمک می کنیم</h3>
        </div>
        <div class="section-content">
          <div class="row">
            <div v-if="information" class="col-lg-4 col-md-12 col-sm-12 col-12">
              <div class="contact-card-area">
                <div class="row justify-content-center">
                  <div class="col-lg-12 col-md-6 col-sm-12 col-12">
                    <div class="contact-card">
                      <i class="fas fa-map-marker-alt"></i>
                      <h4>موقعیت ما</h4>
                      <p>{{ information?.settings?.site?.address }}</p>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6 col-sm-12 col-12">
                    <div class="contact-card">
                      <i class="fas fa-phone-alt"></i>
                      <h4>تلفن ما</h4>
                      <h5>پشتیبانی:</h5>
                      <p>
                        <a
                          :href="`tel:${information?.settings?.site?.support_number}`"
                          class="fontsize-xheavy font-weight-bold"
                          >{{ information?.settings?.site?.support_number }}</a
                        >
                      </p>
                      <h5>گارانتی:</h5>
                      <p>
                        <a
                          :href="`tel:${information?.settings?.site?.guarantee_number}`"
                          class="fontsize-xheavy font-weight-bold"
                        >
                          {{ information?.settings?.site?.guarantee_number }}
                        </a>
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6 col-sm-12 col-12">
                    <div class="contact-card">
                      <i class="far fa-envelope"></i>
                      <h4>ایمیل</h4>
                      <p>
                        <a
                          :href="`mailto: ${information?.settings?.site?.email}`"
                        >
                          {{ information?.settings?.site?.email }}</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-8 col-md-12 col-sm-12 col-12">
              <div class="contact-page-form-area">
                <div class="bd-form details-text-area" id="bd-form">
                  <h3>ارسال پیام</h3>
                  <form>
                    <div class="row">
                      <div class="col-md-6">
                        <input
                          type="text"
                          ref="name"
                          class="form-control border"
                          placeholder="نام"
                          required
                        />
                      </div>
                      <div class="col-md-6">
                        <input
                          type="tel"
                          ref="phone"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          class="form-control border"
                          placeholder="تلفن"
                          required
                        />
                      </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          ref="subject"
                          class="form-control border"
                          placeholder="موضوع"
                          required
                        />
                      </div>
                      <div class="col-md-12">
                        <textarea
                          ref="body"
                          rows="5"
                          class="form-control border"
                          placeholder="پیام شما"
                          required
                        ></textarea>
                      </div>
                      <div
                        v-if="question"
                        class="col-md-6 d-flex align-items-center mt-3"
                      >
                        <span class="fontsize-small">
                          {{ question }}
                        </span>
                        <input
                          id="answerBox"
                          ref="answer"
                          class="form-control border"
                          type="text"
                        />
                      </div>
                      <div class="col-md-12">
                        <button
                          :class="{ disabled: disabled }"
                          @click.prevent="sendForm"
                          class="default-button"
                          type="submit"
                        >
                          <span>ارسال پیام</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      code: null,
      question: null,
      disabled: false,
    };
  },
  computed: {
    information() {
      return this.$store.getters["front/getLandingHome"];
    },
  },
  mounted() {
    this.$axios.get("all/contacts/create").then((response) => {
      this.code = response.data.data.name;
      this.question = response.data.data.message;
    });
  },
  methods: {
    sendForm() {
      if (this.$refs.name.value.length < 2) {
        this.$refs.name.classList.add("inputError");
        return window
          .swal({
            title: "وارد کردن  نام الزامی است",
            text: "نام باید شامل دو کارکتر یا بیشتر باشد",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.name.focus();
          });
      } else {
        this.$refs.name.classList.remove("inputError");
      }
      if (this.$refs.phone.value.length != 11) {
        this.$refs.phone.classList.add("inputError");
        return window
          .swal({
            title: "ورود کردن شماره تماس الزامی است",
            text: "شماره تماس شامل 11 رقم است",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.phone.focus();
          });
      } else {
        this.$refs.phone.classList.remove("inputError");
      }
      if (this.$refs.subject.value.length < 3) {
        this.$refs.subject.classList.add("inputError");
        return window
          .swal({
            title: "موضوع ارتباط را به درستی وارد کنید",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.subject.focus();
          });
      } else {
        this.$refs.subject.classList.remove("inputError");
      }
      if (this.$refs.body.value.length < 3) {
        this.$refs.body.classList.add("inputError");
        return window
          .swal({
            title: "متن پیام خود را وارد کنید",
            text: "این متن نباید از 10 کارکتر کمتر باشد",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.body.focus();
          });
      } else {
        this.$refs.body.classList.remove("inputError");
      }
      if (this.$refs.answer.value.length < 1) {
        this.$refs.answer.classList.add("inputError");
        return window
          .swal({
            title: "پاسخ سوال امنیتی را وارد کنید",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.answer.focus();
          });
      } else {
        this.$refs.answer.classList.remove("inputError");
      }
      const formData = new FormData();
      formData.append("name", this.$refs.name.value);
      formData.append("phone_number", this.$refs.phone.value);
      formData.append("subject", this.$refs.subject.value);
      formData.append("body", this.$refs.body.value);
      formData.append("" + this.code, this.$refs.answer.value);

      this.disabled = true;
      this.$axios
        .post("/all/contacts", formData)
        .then((res) => {
          this.disabled = false;
          this.$refs.name.value = null;
          this.$refs.phone.value = null;
          this.$refs.subject.value = null;
          this.$refs.body.value = null;
          this.$refs.answer.value = null;
          window.swal({
            title: "ارتباط با آریا برقرار شد",
            text: "پیام شما در سیستم ما درج و به زودی پاسخ آنرا دریافت خواهید کرد باتشکر!",
            icon: "success",

            showConfirmButton: true,
            button: "متوجه شدم",
          });
        })
        .catch((err) => {
          this.disabled = false;
          window
            .swal({
              title: "پاسخ سوال امنیتی را  به درستی وارد نکرده اید",
              icon: "warning",

              showConfirmButton: true,
              dangerMode: true,
              button: "متوجه شدم",
            })
            .then(() => {
              this.$refs.answer.focus();
            });
        });
    },
  },
};
</script>

<style scoped>
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.inputError {
  border: 1px solid red !important;
  background: #efe1e4;
}
</style>
