<template>
  <article
    id="newProductArticle"
    v-if="product"
    class="container rounded-sm"
    :style="lowOpacity ? 'opacity: 0.7' : ''"
  >
    <router-link
      :to="
        product
          ? {
              name: 'product.detail',
              params: { id: product.id, slug: product.slug },
            }
          : ''
      "
      class="row align-items-center justify-content-between text-start"
    >
      <div
        class="recommended-products-img text-start col-3"
        :style="
          !product
            ? 'background-image: radial-gradient(#f4f4f4,#f4f4f4,#f4f4f4)'
            : ''
        "
      >
        <img
          loading="lazy"
          v-if="product && mainImage"
          class=""
          :src="getImage(mainImage, 'lg')"
          :alt="product.title"
        />
        <img
          loading="lazy"
          v-else
          class="skeleton-image"
          :alt="product ? product.title : ''"
        />
      </div>

      <div v-if="product" class="text-start position-relative col-9">
        <h6
          class="hide-by-hover text-color-444 weight-bold fontsize16 pt-2 mb-0"
        >
          {{ product.title.substr(0, 33) }}
          {{ product.title.length > 33 ? "..." : "" }}
        </h6>
        <div v-if="product.status == 'available'" class="hide-by-hover">
          <span class="text-color-themeRed fontsize16">
            {{ mainPrice | price }}
          </span>
          <span class="text-color-666 fontsize-small pr-1">تومان</span>
          <span v-if="discountAmount != 0" class="pr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-chevron-double-right"
              viewBox="0 0 16 16"
            >
              <path
                fill="#aaa"
                fill-rule="evenodd"
                d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
              />
              <path
                fill="#aaa"
                fill-rule="evenodd"
                d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
            <span class="line-through text-color-999 fontsize-small">{{
              (mainPrice + discountAmount) | price
            }}</span>
          </span>
        </div>
        <div v-else class="hide-by-hover">
          <span
            v-if="product.status == 'out_of_stock'"
            class="text-color-themeRed fontsize14"
          >
            ناموجود
          </span>
          <span
            v-if="product.status == 'soon'"
            class="text-color-themeRed fontsize14"
          >
            به زودی
          </span>
        </div>
      </div>
      <div v-else class="mt-3">
        <b-skeleton />
        <b-skeleton class="mt-2" />
      </div>
    </router-link>
    <img
      class="vipLable"
      v-if="$route.path != '/' && product.is_vip"
      src="@/assets/images/vip/vip.gif"
      alt="محصول وی آی پی"
    />
  </article>
</template>

<script>
import { BFormRating, BSkeleton } from "bootstrap-vue";

import { getImages, sortVarieties, getImage } from "@/libraries/functions";
import DiscountTag from "./DiscountTag";
export default {
  name: "Product",
  components: { DiscountTag, BFormRating, BSkeleton },
  props: ["product", "lowOpacity"],
  data() {
    return {
      showVideoBox: false,
      playerOptions: null,
    };
  },
  methods: {
    getImage,
    showVideo(video) {
      this.playerOptions = video;
      this.showVideoBox = true;
    },
  },
  computed: {
    mainPrice() {
      return this.product ? this.product.major_final_price.amount : null;
    },
    discountAmount() {
      return this.product ? this.product.major_final_price.discount_price : null;
    },
    mainImage() {
      return this.product ? this.product.major_image : null;
    },
  },
};
</script>

<style scoped>
#newProductArticle {
  background-color: #f4f2f2;
}
#newProductArticle .recommended-products-img {
  width: 100%;
  max-height: 100%;
  padding: 10px;
  background-image: none;
  position: relative;
}
#newProductArticle .recommended-products-img > *:first-child {
  width: auto;
  position: relative;
  max-height: 100%;
}
@media screen and (max-width: 768px) {
  .recommended-content-item article:hover .hide-by-hover {
    opacity: 1 !important;
  }
}

.skeleton-image {
  width: 308px;
  display: inline-block;
}
</style>
<style>
.product-rate .b-rating.disabled,
.b-rating:disabled {
  background-color: unset !important;
}
.product-rate .form-control {
  padding: 0;
  border: unset !important;
  width: 130px;
  direction: ltr;
}
.product-rate .b-rating .b-rating-star {
  padding: 0;
}
img.vipLable {
  position: absolute;
  z-index: 10000;
  width: 100px;
  left: 50%;
  transform: translateX(-50%);
  top: -50px;
}
</style>
