<template>
  <footer>
    <div class="footer footer-2 text-dark ptb-70 bg-ffffff">
      <div class="container">
        <div class="row justify-content-around">
          <div class="col-lg-2 col-md-3 col-sm-12 col-12 pr-4 pr-md-0">
            <div class="footer-links footer-quick-links">
              <h3>لینک های سریع</h3>
              <ul>
                <li>
                  <router-link to="/landing-aboutus"
                    ><i class="fas fa-angle-left"></i>
                    <span>درباره ما</span></router-link
                  >
                </li>
                <li>
                  <router-link to="/landing-contactus"
                    ><i class="fas fa-angle-left"></i>
                    <span>تماس با ما</span></router-link
                  >
                </li>
                <li>
                  <router-link to="/landing-aboutus"
                    ><i class="fas fa-angle-left"></i>
                    <span>اصالت کالا</span></router-link
                  >
                </li>
                <li>
                  <router-link to="/landing-aboutus"
                    ><i class="fas fa-angle-left"></i>
                    <span>افتخارات</span></router-link
                  >
                </li>
                <li>
                  <router-link to="/landing-contactus"
                    ><i class="fas fa-angle-left"></i>
                    <span>اخذ نمایندگی</span></router-link
                  >
                </li>
              </ul>
            </div>
          </div>

          <div
            class="col-lg-5 col-md-6 col-sm-12 col-12"
            style="
              border-radius: 20px;
              background-color: #f8f9fb;
              margin: 10px 20px;
            "
          >
            <div class="footer-logo-area" style="padding: 34px 42px 28px">
              <router-link to="/landing"
                ><img
                  src="../../../../../assets/images/landing/logo.png"
                  alt="image"
              /></router-link>
              <p class="line-height2">
                در تمامی این سالها با هدف ایجاد خدمت رسانی در خریدی آسان و مطمئن
                به همکاران محترم در کنار شما بوده ایم و همه تلاشمان عرضه کالایی
                با کیفیت،و قیمت مناسب که شایسته ایران و ایرانی باشد را داشته ایم
                و خواهیم داشت.امید داریم تا با حمایت شما خدمت رسانی بهتری داشته
                باشیم تا مسیر را برای آیندگان هموار تر سازیم
              </p>
              <!-- <div class="footer-social-icons">
                <span>دنبال کنید:</span>
                <ul>
                  <li>
                    <a
                      :href="footerContent?.settings?.social?.instagram"
                      target="_blank"
                      ><i class="fab fa-instagram"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      :href="footerContent?.settings?.social?.telegram"
                      target="_blank"
                      ><i class="fab fa-telegram"></i
                    ></a>
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12 col-12 pr-4 pr-md-0">
            <div class="footer-links footer-contact-list">
              <h3>اطلاعات تماس</h3>
              <div class="footer-footerContent-card mt-2">
                <!-- <i class="fas fa-map-marker-alt "></i> -->
                <!-- <h5>موقعیت:</h5> -->
                <p class="my-0">
                  <a href="https://goo.gl/maps/LFFvE4dNnyq67gGeA">{{
                    footerContent?.settings?.site?.address
                  }}</a>
                </p>
              </div>
              <div class="footer-footerContent-card mt-2">
                <!-- <i class="fas fa-envelope"></i> -->
                <!-- <h5>ایمیل:</h5> -->
                <p class="my-0">
                  <a :href="`mailto: ${footerContent?.settings?.site?.email}`">
                    {{ footerContent?.settings?.site?.email }}</a
                  >
                </p>
              </div>
              <div class="footer-footerContent-card mt-2">
                <!-- <i class="fas fa-mobile-alt"></i> -->
                <h5 class="my-0">پشتیبانی:</h5>
                <p class="my-0">
                  <a
                    :href="`tel:${footerContent?.settings?.site?.support_number}`"
                    class="fontsize-xheavy font-weight-bold"
                    >{{ footerContent?.settings?.site?.support_number }}</a
                  >
                </p>
              </div>
              <div class="footer-footerContent-card mt-2">
                <h5 class="my-0">گارانتی:</h5>
                <p class="my-0">
                  <a
                    :href="`tel:${footerContent?.settings?.site?.guarantee_number}`"
                    class="fontsize-xheavy font-weight-bold"
                  >
                    {{ footerContent?.settings?.site?.guarantee_number }}
                  </a>
                </p>
              </div>
            </div>
          </div>

          <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="">
              <a
                referrerpolicy="origin"
                target="_blank"
                href="https://trustseal.enamad.ir/?id=339243&amp;Code=jAn5JDv6YsxdTSZQ7pEu"
              >
                <img
                  referrerpolicy="origin"
                  src="https://Trustseal.eNamad.ir/logo.aspx?id=339243&amp;Code=jAn5JDv6YsxdTSZQ7pEu"
                  alt=""
                  style="cursor: pointer"
                  id="jAn5JDv6YsxdTSZQ7pEu"
                />
              </a>
            </div>
          </div> -->
          <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="footer-links footer-newsletter">
              <h3>خبرنامه</h3>
              <p>برای دریافت اخبار به روز ما در خبرنامه ما مشترک شوید!</p>
              <form>
                <input
                  type="text"
                  class="form-control"
                  placeholder="ایمیل شما"
                />
                <button class="default-button" type="submit">
                  <span>ارسال</span>
                </button>
              </form>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="copyright bg-ffffff">
      <div class="container">
        <div class="row align-items-baseline justify-content-between">
          <div class="col-lg-4 col-md-6 col-sm-6 col-12 text-right">
            <p class="d-inline-block">
              تمام حقوق قالب محفوظ است . طراحی و توسعه
              <a href="https://shetabit.com" target="_blank">شتاب</a>
            </p>
          </div>
          <div
            class="col-lg-4 col-md-6 col-sm-6 col-12 footer-social-icons text-dark"
          >
            <!-- <span>دنبال کنید:</span> -->
            <ul
              class="d-flex align-items-center justify-content-end mb-0"
              style="gap: 20px"
            >
              <li>
                <a
                  :href="footerContent?.settings?.social?.instagram"
                  target="_blank"
                  ><i class="fab fa-instagram fontsize-heavy"></i
                ></a>
              </li>
              <li>
                <a
                  :href="footerContent?.settings?.social?.telegram"
                  target="_blank"
                  ><i class="fab fa-telegram fontsize-heavy"></i
                ></a>
              </li>
              <li>
                <a
                  :href="footerContent?.settings?.social?.whatsapp"
                  target="_blank"
                  ><i class="fab fa-whatsapp fontsize-heavy"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    footerContent() {
      return this.$store.getters["front/getLandingHome"];
    },
  },
};
</script>

<style scoped>
.copyright ul li {
  list-style-type: none;
}
</style>
