<template>
  <section v-if="special_categories">
    <div class="container pt-100">
      <div class="default-section-title">
        <h6>برخی از دسته بندی ما</h6>
        <h3>
          ما بهترین محصولات را ارائه می دهیم
        </h3>
      </div>
      <div id="specialCategoriesSwiper" class="swiper-container pt-5">
        <div class="swiper-wrapper">
          <article
            id="specialCateArticle"
            class="swiper-slide"
            v-for="(category, index) in special_categories"
            :key="index"
          >
            <router-link
              to="/login"
            >
              <div class="w-100 rounded">
                <img
                  v-if="category.image"
                  :src="category.image.url"
                  :alt="category.title"
                />
              </div>
              <div class="mt-1">
                <h6
                  class="text-color-444 fontsize15 title-ellipsis"
                  :title="category.title"
                >
                  {{ category.title }}
                </h6>
              </div>
            </router-link>
          </article>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper from "swiper/swiper-bundle.min";

export default {
  computed: {
    special_categories() {
      if (this.$store.getters["front/getLandingHome"] == null) {
        return null;
      } else {
        return this.$store.getters["front/getLandingHome"].special_categories;
      }
    },
  },
  methods: {
    initSpecialCategories(kill = false) {
      this.initSwiper("mySpecialCategories", kill, () => {
        new Swiper("#specialCategoriesSwiper.swiper-container", {
          slidesPerView: "auto",
          spaceBetween: 0,
          loop: false,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".similar-products .swiper-pagination",
            clickable: true,
          },
        });
      });
    },
  },

  mounted() {
    this.initSpecialCategories();
  },
  watch: {
    special_categories(newValue) {
      if (newValue) {
        this.initSpecialCategories(true);
      }
    },
  },
};
</script>

<style scoped>
#specialCateArticle {
  display: inline-block;
  vertical-align: top;
  width: 115px;
  margin: 0 20px;
  text-align: center;
  white-space: normal;
}
#specialCateArticle a img {
  width: 100%;
  min-width: 95px;
  min-height: 95px;
  border-radius: 5px;
}
#specialCateArticle a {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  text-align: center;
  white-space: normal;
}
#specialCateArticle .title-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
