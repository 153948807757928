<template>
  <footer id="bgFooter" class="mt-5 mb-5 mb-md-0">
    <div class="footer footer-2 text-dark ptb-100 py-5 bg-ffffff">
      <div class="container py-4">
        <div class="row justify-content-around">
          <div class="col-lg-2 col-md-12 col-sm-12 col-12 pr-4 pr-md-0">
            <div class="footer-links footer-quick-links">
              <h3>دسترسی سریع</h3>
              <ul>
                <template v-if="!loading">
                  <li
                    v-for="item in content.menu.footer"
                    :key="item.id"
                    class="nav-item fontsize-small mt-3"
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ff762d" style="transform: ;msFilter:;"><path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path></svg>
                    <DynamicLinker :item="item">{{ item.title }}</DynamicLinker>
                  </li>
                </template>
                <template v-else>
                  <li v-for="index in 5" :key="index" class="nav-item">
                    <a class="nav-link">
                      <b-skeleton width="40px" />
                    </a>
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <div class="col-lg-5 col-md-12 col-sm-12 col-12 mr-2 ml-2 mr-md-0 ml-md-0 my-4 my-md-0"
            style="
              border-radius: 20px;
              background-color: #f8f9fb;
              margin: 0 20px;
            "
          >
            <div class="footer-logo-area" style="padding: 25px 35px 25px">
              <router-link to="/"
                ><img
                  src="../../../../assets/images/landing/logo.png"
                  alt="image"
              /></router-link>
              <p v-if="!loading" class="line-height2 py-3">
                {{ content?.settings?.site?.about_us_site }}
              </p>
              <template v-else>
                <b-skeleton width="100%" class="mr-2" /><b-skeleton
                  width="100%"
                  class="mr-2"
                />
                <b-skeleton width="100%" class="mr-2" />
              </template>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12 col-12 pr-4 pr-md-0 ">
            <div class="footer-links footer-contact-list">
              <h3>اطلاعات تماس</h3>
              <div class="footer-footerContent-card mb-3">
                <!-- <i class="fas fa-map-marker-alt "></i> -->
                <!-- <h5 class="fontsize-medium">موقعیت:</h5> -->
                <p class="my-0">
                  <a
                    v-if="!loading"
                    class="my-0"
                    href="https://goo.gl/maps/LFFvE4dNnyq67gGeA"
                    >{{ content?.settings?.site?.address }}</a
                  >
                  <b-skeleton v-else width="270px" />
                </p>
              </div>
              <div class="footer-footerContent-card mb-3">
                <!-- <i class="fas fa-envelope"></i> -->
                <!-- <h5>ایمیل:</h5> -->
                <p class="my-0">
                  <a
                    v-if="!loading"
                    class="my-0"
                    :href="`mailto: ${content?.settings?.site?.email}`"
                  >
                    {{ content?.settings?.site?.email }}</a
                  >
                  <b-skeleton
                    v-else
                    width="105px"
                    class="d-inline-block mr-2"
                  />
                </p>
              </div>
              <div class="footer-footerContent-card mb-3">
                <!-- <i class="fas fa-mobile-alt"></i> -->
                <h5 class="my-0">پشتیبانی:</h5>
                <p class="my-0"> 
                  <a
                    v-if="!loading"
                    :href="`tel:${content?.settings?.site?.support_number}`"
                    class="fontsize-xheavy font-weight-bold my-0"
                    >{{ content?.settings?.site?.support_number }}</a
                  >
                  <b-skeleton
                    v-else
                    width="105px"
                    class="d-inline-block mr-2"
                  />
                </p>
              </div>
              <div class="footer-footerContent-card ">
                <h5 class="my-0">گارانتی:</h5>
                <p class="my-0">
                  <a
                    v-if="!loading"
                    :href="`tel:${content?.settings?.site?.guarantee_number}`"
                    class="fontsize-xheavy font-weight-bold my-0"
                  >
                    {{ content?.settings?.site?.guarantee_number }}
                  </a>
                  <b-skeleton
                    v-else
                    width="105px"
                    class="d-inline-block mr-2"
                  />
                </p>
              </div>
            </div>
          </div>

          <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="">
              <a
                referrerpolicy="origin"
                target="_blank"
                href="https://trustseal.enamad.ir/?id=339243&amp;Code=jAn5JDv6YsxdTSZQ7pEu"
              >
                <img
                  referrerpolicy="origin"
                  src="https://Trustseal.eNamad.ir/logo.aspx?id=339243&amp;Code=jAn5JDv6YsxdTSZQ7pEu"
                  alt=""
                  style="cursor: pointer"
                  id="jAn5JDv6YsxdTSZQ7pEu"
                />
              </a>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="copyright py-3 bg-ffffff">
      <div class="container">
        <div class="row align-items-baseline justify-content-between">
          <div class="col-lg-4 col-md-6 col-sm-6 col-12 text-right">
            <p class="fontsize15">
              تمامی حقوق این سایت مربوط به سایت آریا می باشد . طراحی شده:
              <a
                class="fontsize-medium text-color-theme"
                target="_blank"
                href="https://shetabit.com/"
                >شتاب</a
              >
            </p>
           
          </div>
          <div
            class="col-lg-4 col-md-6 col-sm-6 col-12 footer-social-icons text-dark"
          >
            <!-- <span>دنبال کنید:</span> -->
            <ul
              class="d-flex align-items-center justify-content-end mb-0"
              style="gap: 20px"
            >
              <li>
                <a
                  v-if="!loading"
                  :href="!loading ? content?.settings?.social?.instagram : ''"
                  target="_blank"
                  ><i class="fab fa-instagram fontsize-heavy"></i
                ></a>
              </li>
              <li>
                <a
                  v-if="!loading"
                  :href="!loading ? content?.settings?.social?.telegram : ''"
                  target="_blank"
                  ><i class="fab fa-telegram fontsize-heavy"></i
                ></a>
              </li>
              <li>
                <a
                  v-if="!loading"
                  :href="!loading ? content?.settings?.social?.whatsapp : ''"
                  target="_blank"
                  ><i class="fab fa-whatsapp fontsize-heavy"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import findUrl from "@/libraries/links/LinkResolver";
import DynamicLinker from "./DynamicLinker";
import { BSkeleton } from "bootstrap-vue";

export default {
  name: "Footer",
  components: { DynamicLinker, BSkeleton },
  computed: {
    // دریافت محتوای درخواست هوم
    content() {
      return this.$store.getters["front/getHomeData"];
    },
    loading() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
  },
  data() {
    return {
      email: null,
      disabled: false,
    };
  },
  methods: {
    findUrl,
    sendEmail() {
      if (!this.email) {
        return window.swal({
          title: "خطا!",
          text: "لطفا ایمیل خود را وارد کنید",
          icon: "warning",
          timer: 2000,
        });
      } else if (!this.validEmail(this.email)) {
        return window.swal({
          title: "خطا!",
          text: "لطفا فرمت ایمیل خود را به درستی وارد کنید",
          icon: "warning",
          timer: 2000,
        });
      }
      const fromData = new FormData();
      fromData.append("email", this.email);
      this.disabled = true;
      this.$axios.post("/front/newsletters", fromData).then((response) => {
        this.disabled = false;
        this.email = null;
        window.swal({
          title: "تبریک",
          text: "ایمیل شما برای عضویت در خبرنامه ما ثبت سیستم شد",
          icon: "success",
          timer: 2000,
        });
      });
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>

<style scoped>
#bgFooter {
  box-shadow: 0px 5px 30px -6px rgba(0, 0, 0, 0.1);
}
#bgFooter ul li {
  list-style-type: none;
}
#bgFooter a {
  color: #000000 !important;
}
.imgSkeleton {
  height: 70px;
}

.errorEmail {
  border-bottom: 1px solid red;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.newsletter-btn {
  background: var(--color-theme);
}
.footer-bg input::placeholder {
  color: #ffffff;
}
@media (min-width: 1024px) {
  .footer-call-location {
    position: relative;
  }
  .enamad-container {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
