<template>
  <section>
    <div class="main-banner home-banner-bg">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-7">
            <div class="banner-text-area banner-text-area-2">
              <h6>به آریا جانبی خوش آمدید!</h6>
              <h1>به دنبال خرید سریع و آسان لوازم جانبی هستید!!</h1>
              <p>این یک حقیقت است، در اینجا حق همیشه و کاملا با مشتری است!</p>
              <router-link class="default-button default-button-3" to="/login"
                ><span>ورود به سایت</span></router-link
              >
            </div>
          </div>
          <div v-if="sliders" class="col-lg-5">
            <div class="home-banner-img home-banner-slider-area">
              <div id="landingSwiper" class="swiper-container">
                <div class="swiper-wrapper">
                  <div
                    v-for="(slide, index) in sliders"
                    :key="index"
                    class="swiper-slide"
                  >
                    <img
                      :src="slide.image.url"
                      :alt="slide.title"
                      class="w-100 h-100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper from "swiper/swiper-bundle.min";
export default {
  computed: {
    sliders() {
      if (this.$store.getters["front/getLandingHome"]) {
        return this.$store.getters["front/getLandingHome"].sliders;
      } else {
        return [];
      }
    },
  },
  methods: {
    initLandingSwiper(kill = false) {
      this.initSwiper("mylandingSwiper", kill, () => {
        new Swiper("#landingSwiper.swiper-container", {
          spaceBetween: 0,
          //   centeredSlides: true,
          breakpoints: {
            1024: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            570: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
          },
          speed: 1000,
          loop: false,
          autoplay: {
            delay: 4500,
            disableOnInteraction: false,
          },
        });
      });
    },
  },
  mounted() {
    this.initLandingSwiper();
  },
  watch: {
    sliders() {
      this.initLandingSwiper(true);
    },
  },
};
</script>

<style></style>
