<template>
  <div>
    <div class="w-100" id="topAdvertise">
      <video v-show="isGaurdsAdsShow" class="w-100" autoplay muted loop>
        <source
          src="../../../assets/images/advertise/gaurd.mp4"
          type="video/mp4"
        />
      </video>
      <video v-show="!isGaurdsAdsShow" class="w-100" autoplay muted loop>
        <source
          src="../../../assets/images/advertise/top.mp4"
          type="video/mp4"
        />
      </video>
    </div>
    <header
      class="d-flex align-items-center justify-content-between px-lg-4 px-3 py-2"
    >
      <div
        class="d-lg-flex d-none align-items-center justify-content-center products-list-logo"
      >
        <Logo />
      </div>
      <div
        class="w-100 d-lg-flex d-none align-items-center justify-content-start"
        style="flex: 1"
      >
        <MegaMeun />
      </div>

      <div class="d-flex d-lg-none align-items-center justify-content-start">
        <button
          type="button"
          id="all-categories"
          class="all-categories bg-transparent resp-menu-btn d-lg-none ml-2"
          @click="$emit('openRespMenu')"
        >
          <MenuBar />
        </button>
        <div class="mx-lg-0 mx-auto">
          <router-link to="/" class="logo-img d-inline-block">
            <img
              loading="lazy"
              src="@/assets/images/landing/logo.png"
              class="w-100 h-100"
              alt="atlasmode"
            />
          </router-link>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end header-icons">
        <button
          class="bg-transparent"
          type="button"
          @click="showCloseSearchBox()"
        >
          <searchSvg />
        </button>

        <button class="bg-transparent" type="button" @click="cartListsOpen()">
          <CartButton />
        </button>
        <LoginUser :atlasTheme="true" />
      </div>
    </header>
  </div>
</template>

<script>
import searchSvg from "./svg/Search.vue";
import CartButton from "./cart/CartButton.vue";
import { cartListsOpen, openSearch } from "@/libraries/functions";
import Logo from "@/parts/Front/components/AtlasLogo";
import MegaMeun from "../components/MegaMenu.vue";
import LoginUser from "@/parts/Front/components/LoginUser";
import UserSvg from "./svg/User.vue";
import MenuBar from "./svg/MenuBar.vue";
export default {
  components: {
    searchSvg,
    Logo,
    MegaMeun,
    CartButton,
    LoginUser,
    UserSvg,
    MenuBar,
  },
  data() {
    return {
      isGaurdsAdsShow: false,
    };
  },
  computed: {
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
  },
  watch: {
    "$route.query"(newValue) {
      if (newValue.category_id && newValue.category_id == 4) {
        this.isGaurdsAdsShow = true;
      } else {
        this.isGaurdsAdsShow = false;
      }
    },
  },
  methods: {
    cartListsOpen() {
      cartListsOpen();
      this.$router.push(this.$route.fullPath + "#cart-modal");
    },
    showCloseSearchBox() {
      openSearch();
    },
  },
};
</script>

<style scoped>
.header-icons button {
  padding: 0 12px 0 0;
}
.products-list-logo {
  position: absolute;
  top: 50%;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 0px 13px;
  z-index: 99;
}
</style>
