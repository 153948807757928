import { render, staticRenderFns } from "./SearchSection.vue?vue&type=template&id=5941e6c8&scoped=true&"
import script from "./SearchSection.vue?vue&type=script&lang=js&"
export * from "./SearchSection.vue?vue&type=script&lang=js&"
import style0 from "./SearchSection.vue?vue&type=style&index=0&id=5941e6c8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5941e6c8",
  null
  
)

export default component.exports