<template>
  <section>
    <div>
      <landing-header v-if="$route.hash==''" />
      <main>
        <router-view></router-view>
      </main>
      <landing-footer v-if="$route.hash==''" />
    </div>
  </section>
</template>

<script>
import LandingHeader from "@/parts/Front/components/Landing/Index/LandingHeader.vue";
import LandingFooter from "@/parts/Front/components/Landing/Footer/LandingFooter.vue";
export default {
  components: { LandingHeader, LandingFooter },
  created() {
    this.$store.dispatch("front/getLandingHomeFromServer");
  },
};
</script>

<style lang="css" src="@/assets/css/landingStyle.css"></style>
<style lang="css" src="@/assets/css/landingResponsive.css"></style>
