<template>
  <section>
    <div class="contact-page ptb-70">
      <div class="container">
        <div class="default-section-title">
          <h6>آموزش خرید از آریا جانبی</h6>
          <h3>چگونه می توانید از آریا جانبی خرید کنید</h3>
        </div>
        <div class="section-content mt-4">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-12">
               <!-- <div class="h_iframe-aparat_embed_frame"><span style="display: block;padding-top: 57%"></span><iframe src="https://www.aparat.com/video/video/embed/videohash/49Npl/vt/frame?autoplay=true" allow="autoplay" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div> -->
              <div class="w-100" id="18327710497">
                <script
                  type="text/JavaScript"
                  src="https://www.aparat.com/embed/49Npl?data[rnddiv]=18327710497&data[responsive]=yes&autoplay=true"
                ></script>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "landingGuideline",
};
</script>

<style></style>
