<template>
  <section>
    <div class="about about-1 ptb-100">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="about-img-area">
              <div class="about-img-1">
                <img
                  :src="`https://api.ariyajanebi.ir/storage/${settings?.settings?.landing?.landing_aboutus_1}`"
                  alt="تصویر درباره ما لندینگ"
                />
              </div>
              <div class="about-img-2">
                <img
                  :src="`https://api.ariyajanebi.ir/storage/${settings?.settings?.landing?.landing_aboutus_2}`"
                  alt="تصویر درباره ما لندینگ"
                />
                <!-- <a
                  class="video-popup"
                  href="https://www.youtube.com/watch?v=0qNsC-NLuhM"
                  ><i class="flaticon-play-button"></i
                ></a> -->
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="about-text-area">
              <div class="default-section-title">
                <h6>درباره ما</h6>
                <h3>12 سال افتخار خدمت رسانی در زمینه لوازم جانبی موبایل</h3>
              </div>
              <p>
                سال 1390 با نام دلفین طلایی در شهر مشهد مقدس با فروشگاهی به
                مساحت 30 متر با هدف ایجاد بستری مفید و حل مشکلات موجود در بازار
                در عرضه خدمات لوازم جانبی از کشور چین به کشور عزیزمان ایران شروع
                به کار کردیم تا بتوانیم کالایی با کیفیت، با اصالت و تضمین شده را
                به بازار عرضه کنیم.
              </p>
              <p>
                در سال 1399 به لطف خداوند و دلگرمی مشتریان، شعبه استان گلستان در
                شهر گنبد با مساحت 24 متر افتتاح گردید، در طی این سالها با تمام
                فراز و نشیب ها فراوان با ایمان به مسیر پیش رو به حرکت خود ادامه
                دادیم تا به امروز که پر قدرت از همیشه در کنار شما سروران ایستاده
                ایم و این شروع مسیر ماست در کنار شما....
              </p>
              <div class="about-list">
                <ul>
                  <li>
                    <i class="far fa-check-square"></i>
                    افتتاح دو فروشگاه فعال در شهر مقدس مشهد و گرگان
                  </li>
                  <li>
                    <i class="far fa-check-square"></i>
                    ثبت گارانتی سلامت فیزیکی کالا با نام آریا گستر
                  </li>
                  <li>
                    <i class="far fa-check-square"></i>
                    ایجاد اشتغال برای بالای 50 نفر
                  </li>
                  <li>
                    <i class="far fa-check-square"></i>
                    قانونی بودن تمامی کالاهای وارداتی
                  </li>
                </ul>
              </div>
              <router-link
                :class="{ 'd-none': this.$route.path == '/landing-aboutus' }"
                class="default-button"
                to="/landing-aboutus"
                ><span>اطلاعات بیشتر</span></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    settings() {
      return this.$store.getters["front/getLandingHome"];
    },
  },
};
</script>

<style></style>
