<template>
  <section>
    <LandingAboutVue />
    <LandingFaqVue />
  </section>
</template>

<script>
import LandingAboutVue from "@/parts/Front/components/Landing/AboutUs/LandingAbout.vue";
import LandingFaqVue from "@/parts/Front/components/Landing/AboutUs/LandingFaq.vue";
export default {
  components: { LandingAboutVue, LandingFaqVue },
};
</script>

<style></style>
