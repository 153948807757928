<template>
  <section v-if="new_products && new_products.length">
    <div class="portfolio shape-bg ptb-100">
      <div class="container">
        <div class="default-section-title">
          <h6>برخی از محصولات ما</h6>
          <h3>ما بهترین محصولات را ارائه می دهیم</h3>
        </div>
        <div class="section-content mt-5">
          <div id="landingProduct" class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(product, index) in new_products"
                :key="index"
              >
                <article class="landing-product-article">
                  <router-link to="/login">
                    <div class="landing-product-image">
                      <img
                        loading="lazy"
                        v-if="product && product.major_image"
                        class=""
                        :src="getImage(product.major_image, 'lg')"
                        :alt="product.title"
                      />
                      <img
                        loading="lazy"
                        v-else
                        class="skeleton-image"
                        :alt="product ? product.title : ''"
                      />
                    </div>
                    <div class="landing-product-content">
                      <h4 class="text-light fontsize-large text-center">
                        {{ product.title }}
                      </h4>
                    </div>
                  </router-link>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper from "swiper/swiper-bundle.min";
import { BSkeleton } from "bootstrap-vue";
import { getImage } from "@/libraries/functions";
export default {
  components: { BSkeleton },
  methods: {
    getImage,
    initLandingProduct(kill = false) {
      this.initSwiper("mylandingSwiper", kill, () => {
        new Swiper("#landingProduct.swiper-container", {
          slidesPerView: "auto",
          spaceBetween: 50,
          loop: false,
          speed: 500,
          // autoplay: {
          //   delay: 5000,
          //   disableOnInteraction: false,
          // },
          pagination: {
            el: ".similar-products .swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            250: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            500: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 12,
            },
          },
        });
      });
    },
  },
  computed: {
    new_products() {
      if (this.$store.getters["front/getLandingHome"]) {
        return this.$store.getters["front/getLandingHome"].new_products;
      } else {
        return [];
      }
    },
  },
  watch: {
    new_products(newValue) {
      if (newValue) {
        this.initLandingProduct(true);
      }
    },
  },
  mounted() {
    this.initLandingProduct();
  },
};
</script>

<style scoped>
.portfolio .landing-product-article {
  position: relative;
  height: 400px;
  margin: 10px 0;
  overflow: hidden;
  border-radius: 25px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  background: #ffffff;
}

.portfolio .landing-product-image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.portfolio .landing-product-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.portfolio .landing-product-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  transition: height 0.4s ease-in-out;
  overflow: hidden;
  background: linear-gradient(
    0deg,
    #0099FF 10%,
    #00000000 100%
  );
}

.portfolio .landing-product-article:hover .landing-product-content {
  height: 30%;
}

.portfolio .landing-product-content h4 {
  padding: 1rem;
  margin-top: 30px;
}
</style>
