<template>
  <section v-if="new_products" class="mt-5">
    <div>
      <HeaderSection
        :textBtn="'مشاهده بیشتر'"
        :text="'جدیترین ها'"
        :route="'/products?sort=newest'"
      />
      <div id="newProductSwiper" class="swiper-container">
        <div class="swiper-wrapper recommended-content-item">
          <div
            class="swiper-slide"
            v-for="(product, index) in new_products"
            :key="index"
          >
            <Product :product="product"></Product>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Product from "@/parts/Front/components/product/Product";
import Swiper from "swiper/swiper-bundle.min";
import HeaderSection from "@/parts/Front/components/HeaderSection";

export default {
  components: {
    Product,
    HeaderSection,
  },

  methods: {
    async getData() {
      let res = await this.$store.dispatch("front/getIndexPageContent", 'new-products');
      this.new_products=res;
    },
    initNewProduct(kill = false) {
      this.initSwiper("myNewProduct", kill, () => {
        new Swiper("#newProductSwiper.swiper-container", {
          spaceBetween: 50,
          loop: false,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".similar-products .swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            250: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            992: {
              slidesPerView: 5.3,
              spaceBetween: 12,
            },
            // 1200: {
            // slidesPerView: 3,
            // spaceBetween: 0,
            // }
          },
        });
      });
    },
  },
  watch: {
    new_products(newValue) {
      if (newValue) {
        this.initNewProduct(true);
      }
    },
  },
  mounted() {
    this.getData();
    this.initNewProduct();
  },
  data() {
    return {
      new_products: null,
    };
  },
};
</script>

<style></style>
